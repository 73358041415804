import userStore from '../users/store'
import localStorageUtils from '@/utils/localStorageUtils'
import overlayStore from '../overlays/store'
import BrandingSurveyOverlay from './BrandingSurveyOverlay.vue'
import { trackGoal } from '@/utils/plausible'

const localStorageKey = 'lasso.dk/branding-baseline-spring-2024-survey/shown'

export async function initBrandingSurvey () {
  const startDate = new Date('2024-06-12T13:00:00Z')
  const endDate = new Date(startDate)
  endDate.setDate(endDate.getDate() + 7)

  // Only show within the specified date range
  const date = new Date()
  if (date < startDate || date > endDate) return

  // Don't show for logged in users
  if (userStore.loadUserPromise) await userStore.loadUserPromise
  if (userStore.user) return

  // Wait 30 seconds
  await new Promise(resolve => setTimeout(resolve, 30000))

  // Don't show if already shown
  if (getShown()) return

  setShown(true)
  overlayStore.showOverlay(BrandingSurveyOverlay)
  trackGoal('Branding Baseline Spring 2024 survey shown', {})
}

function getShown () {
  return localStorageUtils.getJSON(localStorageKey) === true
}

function setShown (shown: boolean) {
  localStorageUtils.setJSON(localStorageKey, shown)
}
