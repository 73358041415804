<template>
  <Checkbox
    dense
    required
    label="Ja, jeg giver Lasso X lov til at kontakte mig angående Lassos løsning og behovet for erhvervsoplysninger og markedsdata."
    :modelValue="value"
    :errorText="errorText"
    @change="value => $emit('change', value)"
  />
</template>

<script lang="ts">
import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import Checkbox from 'vue-lasso-core/components/Checkbox.vue'

@Component({
  components: {
    Checkbox,
  },
})

export default class AcceptMarketingField extends Vue {
  @Model('change', Boolean)
  readonly value!: boolean
  @Prop({ type: String, })
  readonly errorText?: boolean
}
</script>
