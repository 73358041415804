<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import windowSize from '@/window-size'
import Icon from 'vue-lasso-core/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class BrandingSurveyOverlay extends Vue {
  get layout () {
    if (windowSize.width > 768) return 'desktop'
    return 'mobile'
  }
}
</script>

<template>
  <div
    :class="[
      'lassodk-BrandingSurveyOverlay',
      `lassodk-BrandingSurveyOverlay--layout-${layout}`,
    ]"
  >
    <div class="lassodk-BrandingSurveyOverlay-close-button-wrapper">
      <button
        class="lassodk-BrandingSurveyOverlay-close-button"
        icon="close"
        @click="() => $emit('close')"
      >
        <Icon
          class="lassodk-BrandingSurveyOverlay-close-button-icon"
          icon="close"
          style="color: inherit;"
        />
      </button>
    </div>

    <div class="lassodk-BrandingSurveyOverlay-inner">
      <h1 class="lassodk-BrandingSurveyOverlay-title">
        Et par hurtige spørgsmål
      </h1>

      <div class="lassodk-BrandingSurveyOverlay-embed-container">
        <iframe
          class="lassodk-BrandingSurveyOverlay-embed-iframe"
          src="https://app.opinionx.co/31fb19ea-36b4-4d14-b84a-417c94c2cb09"
        />
      </div>

      <div class="lassodk-BrandingSurveyOverlay-description">
        Vi arbejder på at forbedre Lasso.dk. Dine svar på spørgsmålene er en stor hjælp, og vi takker mange gange for, at du vil tage dig tiden til at svare!
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.lassodk-BrandingSurveyOverlay {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', 'Roboto', Arial, sans-serif;
  background-color: #f3f3f3;

  &-close-button-wrapper {
    position: sticky;
    z-index: 1;
    top: 80px;
    display: flex;
    height: 0;

    &:is(#app.xs *) {
      top: 70px;
    }
  }

  &-close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-left: auto;
    color: white;
    background-image: linear-gradient(180deg, #ffa07a, #ff8e61);
    border-radius: 9999px;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    will-change: transform;

    &:hover {
      transform: scale(0.9);
    }
  }

  &-inner {
    display: grid;
    grid-auto-rows: auto;
    width: 100%;
    flex-grow: 1;

    & > * {
      grid-column: 2;
    }
  }

  &-title {
    margin-top: auto;
    color: black;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }

  &-embed-container {
    border: 1px solid #F1F1F1;
    border-radius: 16px;
    background-color: white;
    overflow: hidden;
  }

  &-embed-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  &-description {
    margin-bottom: auto;
    color: #616161;
    font-size: 13px;
    line-height: 20px;
  }

  &--layout-mobile &-close-button {
    margin-top: 24px;
    margin-right: 24px;
  }

  &--layout-mobile &-inner {
    grid-template-rows: auto minmax(480px, 515px) auto;
    grid-template-columns: auto 100% auto;
    padding-top: 91px;
    padding-inline: 16px;
    padding-bottom: 32px;
  }

  &--layout-mobile &-title {
    padding-inline: 17px;
    margin-bottom: 32px;
  }

  &--layout-mobile &-embed-container {
    padding-inline: 16px;
  }

  &--layout-mobile &-description {
    padding-inline: 16px;
    margin-top: 40px;
  }

  &--layout-desktop &-close-button {
    margin-top: 48px;
    margin-right: 48px;
  }

  &--layout-desktop &-inner {
    grid-template-rows: auto 480px auto;
    grid-template-columns: auto min(100%, 700px) auto;
    padding-top: 103px;
    padding-inline: 32px;
    padding-bottom: 64px;
  }

  &--layout-desktop &-title {
    padding-inline: 4px;
    margin-bottom: 48px;
  }

  &--layout-desktop &-embed-container {
    padding-inline: 56px;
  }

  &--layout-desktop &-description {
    padding-inline: 15px;
    margin-top: 40px;
  }
}
</style>
