<template>
  <LoginDialogTemplate
    v-if="show"
    class="login-to-show-company-dialog"
    :options="{
      ...options,
      redirectUrl,
    }"
  >
  </LoginDialogTemplate>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import LoginDialogTemplate from '@/components/LoginDialogTemplate/LoginDialogTemplate.vue'
import userStore from '@/modules/users/store'
import { lassoxPortalUrl } from '@/lasso-urls'

@Component({
  components: {
    LoginDialogTemplate,
  },
})

export default class LoginToShowCompanyDialog extends Vue {
  get show () {
    return !!userStore.loginToShowCompanyDialogOptions
  }
  
  get options () {
    return userStore.loginToShowCompanyDialogOptions
  }

  get lassoId () {
    return userStore.loginToShowCompanyDialogOptions?.lassoId
  }

  get redirectUrl () {
    if (!this.lassoId) return null
    return `${lassoxPortalUrl}/${this.lassoId}`
  }
}
</script>
