import 'whatwg-fetch'

import Vue from 'vue'

// App component
import App from '@/App'

import VueKeepAliveDev from 'vue-keep-alive-dev'
import Vuebar from 'vuebar'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import VueResource from 'vue-resource'
import VueFilter from 'vue-filter'
import VueDynamic from 'vue-dynamic'
import { store } from '@/assets/js/store.js'
import router, {redirectToLassoX} from './router/router'
import { EventBus } from '@/assets/js/helpers.js'
import filters from '@/assets/js/filters.js'
import directives from '@/assets/js/directives.js'
import userStore from '@/modules/users/store'
import { lassoxPortalUrl } from '@/lasso-urls'

// Vue Resize
import 'vue-resize/dist/vue-resize.css'
import VueResize from 'vue-resize'

import { trackEvent } from './utils/googleAnalytics'

Vue.use(VueKeepAliveDev)

Vue.use(Vuebar)

if (process.env.NODE_ENV === 'production') {
  Raven.config('https://b3efd6d640fc444e8ea10b108df5b67e@sentry.io/1201152').addPlugin(RavenVue, Vue).install()
}

Vue.use(VueResource)
Vue.use(VueFilter)
Vue.use(VueDynamic, {name: 'dynamic'})

Vue.use(VueResize)

// var req = require.context('../assets/img/discover', true, /^(.*\.(jpg$))[^.]*$/igm)
// req.keys().forEach(req)

EventBus.$on('trackEvent', function (event) {
  if (event) {
    event.event = event.event || 'customEvent'
    if (window.dataLayer) window.dataLayer.push(event)
  }
})

EventBus.$on('trackGoal', ({ goalName, config = {} }) => {
  if(goalName && window.plausible)
    window.plausible(goalName, config)
  // Plausible isn't activated locally per default (check index.html), so call the callback manually
  else if(window.location.hostname == 'localhost')
    config.callback?.()
})

EventBus.$on('navigate', function (data) {
  router.push(data)
})

/* setup filters */
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key])
})

Object.keys(directives).forEach(function (key) {
  Vue.directive(key, directives[key])
})

// Register components here to be able to dynamically instantiate them from string gotten IC Robotics through vue-dynamic

Vue.component('preview-list',
  {
    template: '<span v-on:click="popUp" class="preview-list" :class="trackingClass"><div class="list-content" v-html="content"></div><slot/></span>',
    name: 'previewList',
    props: {
      // The props comes from the text generated in IC Robotics, before being dynamically transformed to a Vue component
      'list': {
        type: String,
        required: true
      },
      'clip': {
        type: Boolean,
        default: true
      },
      'platformLink': {
        type: String,
        required:false
      }
    },
    computed: {
      content: function () {
        var finalList = this.list.split('||')
        return finalList.map((e) => { return '<div class="preview-item' + (this.clip ? ' ellipsis' : '') + '">' + e + '</div>' }).join('') // + (remaining ? '.. og ' + remaining + ' andre' : '')
      },
      trackingClass: function () {
        if(!this.platformLink)
          return
        return 'plausible-event-name=person+resume+preview+list+click'
      },
		},
		methods: {
			popUp: function(e) {
        const url = `${lassoxPortalUrl}${this.platformLink ?? '/' + this.$route.meta.lassoIdPrefixes + this.$route.params.id}`
        if(userStore.user)
          location.href = url
        else
          userStore.showLoginDialog({redirectUrl: url, trackingEventName: 'person+resume+preview+list+click'})
			}
		}
  }
)


userStore.loadUser().then(() => {
  // Redirect to lassoX if logged in and on a page that has an X equivalent
  if (userStore.user) {
    const path = window.location.pathname
    const query = window.location.search
    const fullPath = path + query
    // Router doesn't know the current route yet, so we have to match it manually, before stuff is rendered
    const route = router.match(fullPath)
    if (route.name === 'companyStory' || route.name === 'company' || route.name === 'person') {
      let lassoxRedirect = `${lassoxPortalUrl}/${route.meta?.lassoIdPrefixes}${route.params.id}/${
        route.name === 'companyStory' ? `news?${route.params.storyId}` : `dashboard`}`
      EventBus.$emit('trackGoal', {
        goalName: 'redirect user to platform',
        config: {
          props: {
            redirectUrl: lassoxRedirect
          },
          callback: () => location.href = lassoxRedirect
        }
      })
      return
    }
  }

  new Vue({
    el: '#app',
    store,
    router,
    template: '<App/>',
    components: { App },
    methods: {
      trackEvent,
    },
    created () {

    }
  })
})
