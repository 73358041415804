import Vue from 'vue'
import Vuex from 'vuex'
import { Contact, Entity, Account, Story, Cookie } from '@/assets/js/helpers.js'
import router from '@/router/router'

Vue.use(Vuex)

try {
  // check or decide if user is tester for ads
  var isTester = localStorage.getItem('isAdTester')
  if (isTester === null) {
    const splitPercentage = ['www.lasso.dk', 'lasso.dk'].indexOf(document.location.host) === 0 ? 10 : 100
    isTester = Math.floor(Math.random() * 100) < splitPercentage // 10% of all users become ad testers.
    localStorage.setItem('isAdTester', isTester)
  } else isTester = isTester === 'true'
} catch {}

export const store = new Vuex.Store({
  state: {
    history: [],
    entities: {},
    names: {},
    stories: {},
    front: {},
    lists: {},
    followed: JSON.parse(window.localStorage.getItem('followed')) || {},
    followerName: window.localStorage.getItem('followerName') || '',
    followerEmail: window.localStorage.getItem('followerEmail') || '',
    followerCompany: window.localStorage.getItem('followerCompany') || '',
    followerCount: 0,
    isAdTester: false
  },
  mutations: {
    // addRandomForBinarySplit (state, number) {
    //   state.randomForBinarySplit = number
    // },
    addEntity (state, entity) {
      // cache related entity names
      store.commit('cacheNames', Entity.extractRelatedCompanyIds(entity))
      Vue.set(state.entities, entity.lassoId, entity)
    },
    addNetwork (state, { lassoId, network }) {
      var entity = state.entities[lassoId]
      if (!entity) throw Error('Cannot add network to entity. Entity has not yet been loaded.')
      else Vue.set(entity, 'network', network)
    },
    addResume (state, { lassoId, resume }) {
      var entity = state.entities[lassoId]
      if (!entity) throw Error('Cannot add resume to entity. Entity has not yet been loaded.')
      else Vue.set(entity, 'resume', resume)
    },
    addStory (state, story) {
      Vue.set(state.stories, story.uniqueId, story)
    },
    cacheNames (state, idNamePairs) {
      idNamePairs.forEach(pair => Vue.set(state.names, pair.id, pair.name.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')))
    },
    addEntityNews (state, { lassoId, news, page, endReached }) {
      var entity = state.entities[lassoId]
      if (!entity) throw Error('Cannot add news to entity. Entity has not yet been loaded.')
      // add all news to cache
      for (var i = 0; i < news.length; i++) {
        if (!state.stories[news[i].uniqueId]) Vue.set(state.stories, news[i].uniqueId, news[i])
      }

      if (!entity.news) Vue.set(entity, 'news', { page: 1, items: news, endReached: !!endReached })
      else {
        entity.news.page = page
        entity.news.items = entity.news.items.concat(news)
        entity.news.endReached = !!endReached
      }
    },
    addListNews (state, { listName, news, page, endReached, saveAs }) {
      listName = saveAs || listName
      // add all news to cache
      for (var i = 0; i < news.length; i++) {
        Vue.set(state.stories, news[i].uniqueId, news[i])
      }
      var list = state.lists[listName]
      if (!list) {
        Vue.set(state.lists, listName, { news: { page: 1, items: news, endReached: !!endReached } })
        list = state.lists[listName]
      } else {
        list.news.page = page
        Vue.set(list.news, 'items', list.news.items.concat(news))
        list.news.endReached = !!endReached
      }
    },
    addFollowedEntity (state, { followerName, followerEmail, followerCompany, lassoId }) {
      Vue.set(state.followed, lassoId, true)
      state.followerName = followerName
      state.followerEmail = followerEmail
      state.followerCompany = followerCompany
      window.localStorage.setItem('followed', JSON.stringify(state.followed))
      window.localStorage.setItem('followerName', followerName)
      window.localStorage.setItem('followerEmail', followerEmail)
      window.localStorage.setItem('followerCompany', followerCompany)
    },
    editFollowerCount (state, { count }) {
      state.followerCount = count
    },
    removeFollowedEntity (state, { lassoId }) {
      Vue.delete(state.followed, lassoId)
      window.localStorage.setItem('followed', JSON.stringify(state.followed))
    }
  },
  actions: {
    loadEntity ({ commit, dispatch }, { lassoId, includeNews, pageSize = 12 }) {
      const getRelatedStories = (lassoId, pageSize) => dispatch('loadEntityNews', { lassoId: lassoId, page: 1, count: pageSize, save: false })

      const type = lassoId.startsWith('CVR-1-')
      ? 'company'
      : 'person'

      return new Promise((resolve, reject) => {
        const entity = store.state.entities[lassoId]

        if (entity && (!includeNews || (includeNews && entity.news))) {
          resolve(store.state.entities[lassoId])
        } else if (entity && includeNews && !entity.news) {
          getRelatedStories(lassoId, pageSize).then(news => resolve(entity))
        } else {
          Promise.all([
            Entity.get(lassoId),
            includeNews
            ? getRelatedStories(lassoId, pageSize)
            : null,

            // type == 'company'
            // ? api.company.getContactsHistory(lassoId)
            // : null,
            null
          ]).then(([
            entity,
            news,
            contactsHistory,
          ]) => {
            commit('addEntity', entity)

            if (includeNews) {
              commit('addEntityNews', { lassoId, news, page: 1, endReached: news.length < pageSize })
            }

            resolve(entity)
          })
        }
      })
    },
    loadNetwork (commit, lassoId) {
      return new Promise((resolve, reject) => {
        var entity = store.state.entities[lassoId]
        if (entity && entity.network) resolve(entity.network)
        else {
          Entity.network(lassoId).then((network) => {
            store.commit('addNetwork', { lassoId, network })
            resolve(network)
          }, reject)
        }
      })
    },
    loadResume ({ commit, dispatch }, { lassoId, short = true }) {
      return new Promise((resolve, reject) => {
        var entity = store.state.entities[lassoId]
        if (entity && entity.resume && (!entity.resume.shortVersion || short)) {
          resolve(entity.resume)
        }
        else {
          Entity.resume(lassoId, short).then((resume) => {
            dispatch('getAndCacheNames', Story.extractCompanyIds([resume])).then(() => {
              store.commit('addResume', { lassoId, resume })
              resolve(resume)
            }, reject)
          }, reject)
        }
      })
    },
    loadStory ({ state, commit, dispatch }, { storyId, lassoId }) {
      var checkForStatstidende = function (story) {
        return new Promise((resolve, reject) => {
          if (story.statstidende === undefined && (story.storyId === '8.1' || story.storyId === '8.3')) {
            Entity.statstidende(story.mainEntity.lassoId).then((result) => {
              Vue.set(story, 'statstidende', result); resolve(story)
            },
            () => resolve(story))
          } else resolve(story)
        })
      }

      return new Promise((resolve, reject) => {
        var story = store.state.stories[storyId]
        if (story) Promise.all([checkForStatstidende(story), dispatch('getAndCacheNames', Story.extractCompanyIds([story]))]).then((results) => resolve(story), reject)
        else {
          Story.get(storyId, lassoId).then((story) => {
            Promise.all([checkForStatstidende(story), dispatch('getAndCacheNames', Story.extractCompanyIds([story]))]).then((results) => resolve(story), reject).then(results => {
              store.commit('addStory', story)
              resolve(story)
            })
          }, reject)
        }
      })
    },
    loadList ({ commit, dispatch }, { listName, more, count, orderBy = 'promoteduntil', types, saveAs, queryRelated }) {
      return new Promise((resolve, reject) => {
        var existing = store.state.lists[saveAs || listName]
        if (existing && (!more || existing.endReached)) resolve(existing)
        else {
          var page = existing && more ? existing.news.page + 1 : 1
          Story.getList(listName, page, count, orderBy, types).then(function (news) {
            store.commit('addListNews', { listName: listName, news: news, page: page, endReached: news.length < count, saveAs })
            dispatch('getAndCacheNames', [...new Set(news.filter(s => s.queryEntity && s.queryEntity.type === 'company').map(s => s.queryEntity.lassoId))]).then(() => resolve(store.state.lists[listName]))
          }, reject)
        }
      })
    },
    loadEntityNews ({ commit, dispatch }, { lassoId, page, count, save = true }) {
      return new Promise((resolve, reject) => {
        Story.getByLassoIds([lassoId], page, count).then(news => {
          dispatch('getAndCacheNames', [...new Set(news.filter(s => s.mainEntity && s.mainEntity.type === 'company').map(s => s.mainEntity.lassoId))]).then(() => {
            if (save) commit('addEntityNews', { lassoId: lassoId, news: news, page: page, endReached: news.length < count })
            resolve(news)
          })
        })
      })
    },
    unfollow (commit, { lassoId, email }) {
      return new Promise((resolve, reject) => {
        Entity.unfollow(lassoId, email).then(() => {
          store.commit('removeFollowedEntity', { lassoId })
          resolve()
        })
      })
    },
    // cacheStoryEntityNames ({ commit, state, dispatch }, { stories, extract = false, queryRelated = true }) {
    //     return new Promise((resolve, reject) => {
    //         let ids = extract
    //             ? Story.extractCompanyIds(stories) // extract the lassoIds from the content of the article
    //             : [...new Set(stories.filter(s => queryRelated ? (s.queryEntity && s.queryEntity.type === 'company') : (s.mainEntity && s.mainEntity.type === 'company')).map(s => queryRelated ? s.queryEntity.lassoId : s.mainEntity.lassoId))]
    //         dispatch('getAndCacheNames', ids).then(resolve, reject)
    //     })
    // },
    getAndCacheNames ({ commit, state }, lassoIds) {
      return new Promise((resolve, reject) => {
        var ids = lassoIds.filter(id => !state.names[id])
        if (ids.length === 0) resolve()
        else {
          Entity.names(ids).then(pairs => {
            commit('cacheNames', pairs)
            resolve()
          }, reject)
        }
      })
    },
    getFollowed ({state, commit}, token) {
      return new Promise((resolve, reject) => {
        Entity.followedByToken(token).then(followed => {
          followed.entities.forEach(e => commit('addFollowedEntity', { followerName: '', followerEmail: followed.email, lassoId: e.lassoId }))
          resolve(followed)
        })
      })
    },
    confirmNewsletter ({state}, config) {
      return Contact.subscribeNewsletter(config)
    }
  },
})
