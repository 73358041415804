<template>
  <div
    :class="[
      'text-field',
      leadingIcon && 'has-leading-icon',
      (trailingIcon || errorText) && 'has-trailing-icon',
      focused && 'is-focused',
      value && 'has-value',
      errorText && 'has-error',
    ]"
  >
    <div class="field-container">
      <div class="outline" />

      <div class="floating-label">
        <div class="floating-label-text">
          <span>{{ label }}</span>
          <span v-if="required" class="required-asterisk"> *</span>
        </div>
      </div>

      <input
        ref="inputEl"
        class="input"
        :name="name"
        :type="type"
        :autocomplete="autocomplete"
        :value="value"
        @mousedown="$emit('mousedown', $event)"
        @focus="event => {
          focused = true
          $emit('focus', event)
        }"
        @blur="event => {
          focused = false
          $emit('blur', event)
        }"
        @keydown="$emit('keydown', $event)"
        @input="$emit('input', $event.target.value)"
        @change="$emit('change', $event.target.value)"
      >

      <div v-if="leadingIcon" class="leading-icon">
        <Icon :icon="leadingIcon" size="small" />
      </div>

      <div v-if="trailingIcon || errorText" class="trailing-icon">
        <template v-if="trailingIcon">
          <Icon
            v-if="!onClickTrailingIcon"
            :icon="trailingIcon"
            size="small"
          />

          <IconButton
            v-else
            :icon="trailingIcon"
            size="small"
            type="button"
            @click="() => onClickTrailingIcon()"
          />
        </template>

        <Icon
          v-else
          class="error-icon"
          icon="error"
          size="small"
        />
      </div>
    </div>

    <p v-if="!errorText && helperText" class="helper-text">{{ helperText }}</p>

    <p v-if="errorText" class="error-text">{{ errorText }}</p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model, Ref } from 'vue-property-decorator'
import Icon from 'vue-lasso-core/components/Icon.vue'
import IconButton from 'vue-lasso-core/components/IconButton.vue'
import InputBase from 'vue-lasso-core/components/InputBase.vue'

@Component({
  components: {
    Icon,
    IconButton,
    InputBase,
  },
})

export default class TextInput extends Vue {
  @Prop(Boolean)
  readonly autofocus!: boolean

  @Prop(String)
  readonly label?: string

  @Prop(Boolean)
  readonly required!: boolean

  @Prop(String)
  readonly name?: string

  @Prop({ type: String, default: 'text' })
  readonly type!: string

  @Prop(String)
  readonly autocomplete?: string

  @Model('input', { type: String, default: '' })
  readonly value!: string

  @Prop(String)
  readonly leadingIcon?: string

  @Prop(String)
  readonly trailingIcon?: string

  @Prop(Function)
  readonly onClickTrailingIcon?: VoidFunction

  @Prop(String)
  readonly helperText?: string

  @Prop(String)
  readonly errorText?: string

  @Ref()
  readonly inputEl!: HTMLInputElement

  focused = false

  mounted () {
    if (this.autofocus) this.inputEl.focus()
  }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import "@shared/style/global.scss";

@mixin deep { ::v-deep { @content } }

.text-field {
  .field-container {
    position: relative;
    display: flex;
    border-radius: 8px;
    color: #616161;
    background-color: white;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.04);
  }

  .outline {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #b7b7b766;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1px;
    transition: color 100ms, box-shadow 100ms;
  }

  .floating-label {
    $scalingFactor: math.div(12, 14);
    $scale: scale($scalingFactor);

    pointer-events: none;
    position: absolute;
    top: 20px;
    left: 16px;
    max-width: calc(100% - (16px * 2));
    color: #b7b7b7;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    box-shadow: none;
    transform: translateX(0) translateY(-50%) scale(1);
    transform-origin: top left;
    transition: (
      max-width 100ms,
      color 100ms,
      transform 100ms,
    );

    &::before {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 0;
      right: 0;
      content: '';
      height: math.ceil(math.div(1px, $scalingFactor));
      background-color: white;
      box-shadow: (
        math.div(4px, $scalingFactor) 0 0 0 white,
        math.div(-4px, $scalingFactor) 0 0 0 white,
      );
      transform: translateY(-50%) scaleX(0);
      transition: transform 100ms;
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .required-asterisk {
      transition: color 100ms;
    }
  }

  .input {
    appearance: none;
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 40px;
    padding: 8px 16px;
    border: 0;
    margin: 0;
    font: inherit;
    color: #616161;
    background-color: transparent;
    border-radius: inherit;

    &:focus {
      outline: none;
    }

    &::-ms-clear {
      display: none;
    }
  }

  .leading-icon,
  .trailing-icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transform: translateY(-50%);

    @include deep {
      .icon-button {
        pointer-events: auto;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0;
      }

      .md-icon {
        margin: auto;
        color: inherit;
      }
    };
  }

  .leading-icon {
    left: 8px;
  }

  .trailing-icon {
    right: 8px;

    .error-icon {
      color: #ef4444;
    }
  }

  .helper-text,
  .error-text {
    padding: 0 16px;
    margin-top: 8px - 4px;
    margin-bottom: -4px;
    font-size: 12px;
    line-height: 20px;
  }

  .helper-text {
    color: #616161;
  }

  .error-text {
    color: #ef4444;
  }

  &.has-leading-icon,
  &.has-trailing-icon {
    .floating-label {
      max-width: calc(100% - 16px - 48px);
    }
  }

  &.has-leading-icon.has-trailing-icon {
    .floating-label {
      max-width: calc(100% - (48px * 2));
    }
  }

  &.has-leading-icon {
    .input {
      padding-left: 48px;
    }

    .floating-label {
      left: 48px;
    }
  }

  &.has-trailing-icon .input {
    padding-right: 48px;
  }

  &.is-focused,
  &.has-value {
    $scalingFactor: math.div(12, 14);
    $scale: scale($scalingFactor);

    .floating-label {
      max-width: calc((100% - (16px * 2)) / #{$scalingFactor});
      transform: translateX(0) translateY(-19.5px) $scale translateY(-50%);

      &::before {
        transform: translateY(-50%) scaleX(1);
      }
    }

    &.has-leading-icon .floating-label {
      transform: translateX(-32px) translateY(-19.5px) $scale translateY(-50%);
    }
  }

  &.is-focused {
    .outline {
      color: #b7b7b7;
    }
  }

  &.has-error {
    .outline,
    .floating-label {
      color: #ef4444;
    }

    .leading-icon,
    .trailing-icon {
      @include deep {
        .md-icon {
          color: #ef4444;
        }

        .icon-button {
          color: #ef4444;

          &:hover {
            background-color: rgba(#ef4444, 0.08);
          }

          &:focus {
            background-color: rgba(#ef4444, 0.16);
          }

          &:active {
            background-color: rgba(#ef4444, 0.32);
          }
        }
      }
    }
  }
}
</style>
