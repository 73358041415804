<template>
  <div class="signup">
    <LoadingSpinner v-if="loading" />

    <template v-else>
      <Header />

      <form @submit.prevent="submit" class="email-signup" >
        <div class="m-auto" />

        <Error v-if="error" :error="error" />

        <div class="signup-header">
          <h1 class="signup-header-title">Opret gratis bruger</h1>

          <p class="signup-header-description">
            <template>Har du allerede en bruger? </template>
            <a href="#" @click.prevent="() => router.go('login')">Log ind her.</a>
          </p>
        </div>

        <div class="field-group">
          <TextField
            label="Navn"
            name="name"
            required
            autocomplete="name"
            v-model="model.name.value"
            @focus="fieldFocused = true"
            @blur="fieldFocused = false"
          />
        </div>

        <div class="field-group">
          <TextField
            label="E-mail"
            required
            name="email"
            type="email"
            autocomplete="email"
            v-model="model.email.value"
            :errorText="model.email.error"
            @change="validateEmail()"
            @focus="fieldFocused = true"
            @blur="fieldFocused = false"
          />
        </div>

        <div class="field-group">
          <PasswordField
            label="Adgangskode"
            required
            name="password"
            autocomplete="new-password"
            v-model="model.password.value"
            :errorText="model.password.error"
            @change="validatePassword()"
            @focus="fieldFocused = true"
            @blur="fieldFocused = false"
          />
        </div>

        <div v-if="showCheckboxes" class="field-group">
          <Checkbox
            dense
            v-model="model.termsAccepted.value"
            required
            :errorText="model.termsAccepted.error"
            @change="validateTermsAccepted()"
          >
            <template v-slot:label>
              <span>Jeg accepterer <a href="https://apps.lassox.com/assets/docs/terms_conditions.pdf" target="_blank">betingelserne</a> og <a href="https://apps.lassox.com/assets/docs/privacy_cookies.pdf" target="_blank">privatlivspolitikken</a>.</span>
            </template>
          </Checkbox>
        </div>

        <div v-if="showCheckboxes" class="field-group">
          <AcceptMarketingField
            v-model="model.marketingAccepted.value"
            :errorText="model.marketingAccepted.error"
            @change="validateMarketingAccepted()"
          />
        </div>

        <div class="m-auto" />

        <div class="footer">
          <Button
            theme="peach"
            :disabled="hasErrors"
            label="Opret"
            type="submit"
          />
        </div>
      </form>
      <div class="m-auto" />

      <div class="separator" />

      <div class="m-auto" />

      <div class="external-login">
        <div class="external-login-buttons">
          <Button
            v-for="provider in externalLoginProviders"
            :key="provider"
            class="external-login-button"
            outlined
            @click="() => openExternalLoginPopup(provider)"
          >
            <div
              class="icon"
              v-html="require(`../../social-icons/${provider}.svg`)"
            />

            <div
              class="label"
              v-text="`Fortsæt med ${provider}`"
            />
          </Button>
        </div>
      </div>

      <div class="m-auto" />
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Header from '../../parts/Header.vue'
import LoadingSpinner from 'vue-lasso-core/components/LoadingSpinner.vue'
import Error from '../../parts/Error.vue'
import TextField from '@/components/new-fields/TextField.vue'
import PasswordField from '@/components/new-fields/PasswordField.vue'
import Checkbox from 'vue-lasso-core/components/Checkbox.vue'
import AcceptMarketingField from '@/components/AcceptMarketingField.vue'
import Button from '@/components/Button.vue'
import LoginDialogTemplateOptions from '../../options'
import router from '../router'
import { validateEmail, validatePassword } from '../../modules/validation'
import userApi from '@/modules/users/api'
import userStore from '@/modules/users/store'
import confirmEmailSentDialogStore from '@/components/ConfirmEmailSentDialog/store'
import { trackGoal } from '@/utils/plausible'
import externalLoginProviders, { Provider } from '../../modules/external-login/providers'
import externalLoginPopup from '../../modules/external-login/popup'
import { lassoxAppsUrl } from '@/lasso-urls'
import { ExternalLoginCallbackResult } from '../../modules/external-login/popup'

@Component({
  components: {
    LoadingSpinner,
    Header,
    Error,
    TextField,
    PasswordField,
    Checkbox,
    AcceptMarketingField,
    Button,
  },
})

export default class Signup extends Vue {
  @Prop({ type: Object, required: true })
  readonly options!: LoginDialogTemplateOptions

  router = router
  externalLoginProviders = externalLoginProviders
  loading = false

  error = ''

  model = {
    name: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    marketingAccepted: {
      value: false,
      error: '',
    },
    termsAccepted: {
      value: false,
      error: '',
    },
  }

  fieldFocused = false
  get showCheckboxes () {
    return this.fieldFocused || this.model.name.value || this.model.email.value || this.model.password.value
  }

  get hasErrors () {
    for (const field of Object.values(this.model)) {
      if (!!field.error) return true
    }

    return false
  }

  validateEmail () {
    this.model.email.error = validateEmail(this.model.email.value).error ?? ''
  }

  validatePassword () {
    this.model.password.error = validatePassword(this.model.password.value).error ?? ''
  }

  validateTermsAccepted () {
    this.model.termsAccepted.error = this.model.termsAccepted.value
      ? ''
      : 'Dette felt er påkrævet'
  }

  validateMarketingAccepted () {
    this.model.marketingAccepted.error = this.model.marketingAccepted.value
      ? ''
      : 'Dette felt er påkrævet'
  }

  validate () {
    this.validateEmail()
    this.validatePassword()
    this.validateTermsAccepted()
    this.validateMarketingAccepted()
  }

  submit () {
    this.validate()

    if (this.hasErrors) return

    this.loading = true

    userApi.register({
      name: this.model.name.value,
      email: this.model.email.value,
      password: this.model.password.value,
      returnUrl: this.options.redirectUrl,
      // acceptedTerms: this.model.termsAccepted.value,
      // acceptedMarketing: this.model.marketingAccepted.value,
    }).then(response => {
      if (!response.ok) {
        switch (response.data.errorCode) {
          case 1: {
            if (response.data.errorMessage.indexOf('nonallowed characters')) {
              // that.signupError = 'Den indtastede e-mail kan ikke benyttes. Prøv venligst igen.'
              // that.signingUp = false
              this.error = 'Den indtastede e-mail kan ikke benyttes. Prøv venligst igen.'
              break
            }
          }

          case 2: {
            if (response.data.errorMessage.indexOf('already taken')) {
              // that.$http.post(that.apiUrl + '/users/login/available-organizations', model).then(function (organizations) {
              //   that.signupError = 'Den indtastede e-mail er allerede i brug'
              // })
              this.error = 'Den indtastede e-mail er allerede i brug.'
              break
            }
          }

          case 58: {
            // sessionStorage.setItem('lasso/signup/bannedEmail', 'true')
            // that.signupError = 'Den indtastede e-mail kan ikke bruges. Prøv med en anden e-mail.'
            // that.signingUp = false
            this.error = 'Den indtastede e-mail kan ikke bruges. Prøv med en anden e-mail.'
            break
          }

          case 59: {
            // that.signupError = 'Du har nået din grænse af prøveperioder. Kontakt venligst Lasso på +45 71 74 78 12.'
            // that.signingUp = false
            this.error = 'Den indtastede e-mail er allerede i brug.'
            break
          }

          default: {
            // that.signupError = 'Der skete en fejl. Prøv igen.'
            // that.signingUp = false
            // Raven.captureException('Signup error: ' + JSON.stringify(response.data))
            this.error = 'Der skete en fejl. Prøv igen.'
            console.error('Email signup failed. Data:', response.data)
            break
          }
        }

        this.loading = false
        return
      }

      trackGoal('Signup', {
        props: {
          provider: 'Email',
          trigger: this.options.trackingEventName.replaceAll('+', ' ')
        }
      })

      userStore.hideLoginDialog()
      confirmEmailSentDialogStore.show(this.model.email.value, response.data.lassoOrganizationId, this.options.redirectUrl || '')
    })
  }

  openExternalLoginPopup (provider: Provider) {

    externalLoginPopup.open(provider).then(result => {

      if (result.type === 'NewUser') {
        trackGoal('Signup', {
          callback: () => this.redirectAfterExternalLogin(result, this.options.redirectUrl),
          props: {
            provider: provider,
            trigger: this.options.trackingEventName.replaceAll('+', ' ')
          },
        })
      } else {
        trackGoal('Login', {
          callback: () => this.redirectAfterExternalLogin(result, this.options.redirectUrl),
          props: {
            provider: provider,
            trigger: this.options.trackingEventName.replaceAll('+', ' ')
          }
        })
      }
    })
    .catch(error => {
      if (error.errorCode === 110) this.error = `Du har allerede en bruger, som logges ind via ${error.errorMessage}`
      else if (error.errorCode === 16) this.error = 'Du har allerede en bruger, som logges ind vha. e-mail.'
      else if (error.errorCode === 58) this.error = 'E-mails fra det valgte domæne er ikke tilladt.'
      else this.error = 'Noget gik galt'
      console.error('External login failed. Error:', error)
    })
  }

  redirectAfterExternalLogin = (result: ExternalLoginCallbackResult, returnUrl?: string) => {
    const redirectUrl = new URL(`${lassoxAppsUrl}/login`)
    redirectUrl.searchParams.set('token', result.signInToken)
    if (returnUrl) redirectUrl.searchParams.set('returnUrl', returnUrl)
    window.location.href = redirectUrl.toString()
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

@mixin deep { &::v-deep { @content } }

.signup {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .signup-header {
    .signup-header-title {
      @include typography('title');
      margin: -4px 0;
    }

    .signup-header-description {
      margin: -4px 0;
      margin-top: 12px - 4px;
    }
  }

  .loading-spinner {
    margin: auto;
  }

  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .error {
      margin-bottom: 24px;
    }

    .field-group {
      margin-top: 24px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 24px;
      column-gap: 16px;
      margin-top: 24px;

      .button {
        margin-left: auto;
      }
    }
  }

  .separator {
    position: relative;
    margin: 24px 0;
    border-top: 1px solid #F1F1F1;

    &::after {
      content: 'eller';
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0 12px;
      font-size: 14px;
      line-height: 32px;
      background-color: white;
      transform: translate(-50%, -50%);
    }
  }

  .external-login {
    padding: 24px 0;
    text-align: center;

    .external-login-buttons {
      display: inline-flex;
      flex-direction: column;

      .external-login-button {
        flex-grow: 1;

        & + .external-login-button {
          margin-top: 16px;
        }

        .icon {
          width: 18px;
          height: 18px;
          margin-left: -4px;
          margin-right: 8px;

          &::v-deep svg {
            width: 100%;
            height: 100%;
            vertical-align: top;
          }
        }

        .label {
          flex-grow: 1;
          text-align: left;
        }
      }
    }
  }
}
</style>
