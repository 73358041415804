export default {
	'boersnoterede-c20': {
		listName: 'boersnoterede-c20',
		title: 'C20',
		imageId: 'https://lasso.blob.core.windows.net/lasso-news-images/boersnoterede-c20.jpg',
		label: '20 firmaer',
		description: 'De firmaer med mest omsatte aktier på Københavns Fondsbørs.'
	},
	'politikere': {
		listName: 'politikere',
		title: 'Politikere',
		imageId: 'https://lasso.blob.core.windows.net/lasso-news-images/politikere.jpg',
		label: '46 personer',
		description: 'Er regeringens ministre også involveret i dansk erhvervsliv?<br/>Følg dem her.'
	},
	'loevens-hule': {
		listName: 'loevens-hule',
		title: 'Løvens Hule',
		imageId: 'https://lasso.blob.core.windows.net/lasso-news-images/loevens-hule.jpg',
		label: '26 personer og firmaer',
		description: 'Startup-virksomheder, der har pitched deres ideer for løverne i Danmarks Radios program'
	},
	'danmarks-rigeste': {
		listName: 'danmarks-rigeste',
		title: 'Danmarks rigeste',
		imageId: 'https://lasso.blob.core.windows.net/lasso-news-images/danmarks-rigeste.jpg',
		label: '100 personer',
		description: 'Personerne og familierne, der pt. er Danmarks rigeste'
	},
	'gossip': {
		listName: 'gossip',
		title: 'Gossip',
		imageId: 'https://lasso.blob.core.windows.net/lasso-news-images/gossip.jpg',
		label: '127 personer',
		description: 'Firmaer, som kendte og kongelige er involveret i.'
	},
	'erhvervseliten': {
		listName: 'erhvervseliten',
		title: 'Magtens elite',
		imageId: 'https://lasso.blob.core.windows.net/lasso-news-images/erhvervseliten.jpg',
		label: '357 personer',
		description: 'Bogen ‘Magteliten’ udpegede de mest magtfulde danskere. Følg dem, der driver virksomheder, her.'
	},
	'under-konkurs-og-tvangsoploesning': {
		listName: 'under-konkurs-og-tvangsoploesning',
		title: 'Seneste konkurser',
		imageId: 'https://lasso.blob.core.windows.net/lasso-news-images/under-konkurs-og-tvangsoploesning.jpg',
		label: 'live liste',
		description: 'Danske virksomheder, der meldes under konkurs eller tvangsopløsning'
	}
}
