import Vue, { VueConstructor } from 'vue'
import Login from './routes/Login.vue'
import Signup from './routes/Signup.vue'

const routes = [
  { name: 'login', component: Login },
  { name: 'signup', component: Signup },
] as const

type RouteName = typeof routes[number]['name']

interface Route {
  name: string
  component: VueConstructor
}

const routesByName = (() => {
  const obj = {} as Record<RouteName, Route>
  for (const route of routes) {
    obj[route.name] = route as Route
  }
  return obj
})()

interface CurrentRoute extends Route {
  props: any
}

const router = new Vue({
  data: () => ({
    current: <CurrentRoute | null> null,
    history: <CurrentRoute[]> [],
  }),

  computed: {
    canGoBack (): boolean {
      return !!this.history.length
    },
  },

  methods: {
    clearHistory () {
      this.history = []
    },

    reset () {
      this.current = null
      this.clearHistory()
    },

    replace (name: RouteName, props?: any) {
      const route = routesByName[name]

      this.current = {
        name: route.name,
        component: route.component,
        props,
      }
    },

    go (name: RouteName, props?: any) {
      if (this.current) this.history.push(this.current)
      this.replace(name, props)
    },

    goBack () {
      this.current = this.history.pop() ?? null
    },
  },
})

export default router
