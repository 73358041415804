import Vue from 'vue'

const state = Vue.observable({
  show: false,
})

const externalLoginWelcomeDialogStore = {
  state,

  show () {
    state.show = true
  },

  hide () {
    state.show = false
  },
}

export default externalLoginWelcomeDialogStore
