<template>
  <div class="home">
    <LoadingSpinner v-if="loading" class="m-auto" />

    <template v-else>
      <Header />

      <div class="m-auto" />

      <Error v-if="error" :error="error" />

      <form
        class="email-login"
        method="post"
        @submit="submitEmailLogin"
      >
        <div class="email-login-header">
          <h1 class="email-login-header-title">Log ind med e-mail</h1>

          <p class="email-login-header-description">
            <template>Ikke bruger endnu? </template>
            <a href="#" @click.prevent="() => router.go('signup')">Opret dig gratis her.</a>
          </p>
        </div>

        <TextField
          label="E-mail"
          required
          name="email"
          type="email"
          autocomplete="email"
          v-model="emailLoginModel.email.value"
          :errorText="emailLoginModel.email.error"
          @change="validateEmail()"
        />

        <PasswordField
          label="Adgangskode"
          required
          name="password"
          autocomplete="current-password"
          v-model="emailLoginModel.password.value"
          :errorText="emailLoginModel.password.error"
          @change="validatePassword()"
        />

        <div class="email-login-footer">
          <p style="margin: -4px 0;">
            <template>Glemt din adgangskode? </template>
            <a href="#" @click.prevent="() => forgotPasswordDialogStore.show()">Klik her.</a>
          </p>

          <Button
            theme="peach"
            :disabled="emailLoginHasErrors"
            label="Log ind"
            type="submit"
          />
        </div>
      </form>

      <div class="m-auto" />

      <div class="separator" />

      <div class="m-auto" />

      <div class="external-login">
        <div class="external-login-buttons">
          <Button
            v-for="provider in externalLoginProviders"
            :key="provider"
            class="external-login-button"
            outlined
            @click="() => openExternalLoginPopup(provider)"
          >
            <div
              class="icon"
              v-html="require(`../../social-icons/${provider}.svg`)"
            />

            <div
              class="label"
              v-text="`Fortsæt med ${provider}`"
            />
          </Button>
        </div>
      </div>

      <div class="m-auto" />
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import LoadingSpinner from 'vue-lasso-core/components/LoadingSpinner.vue'
import Header from '../../parts/Header.vue'
import Error from '../../parts/Error.vue'
import TextField from '@/components/new-fields/TextField.vue'
import PasswordField from '@/components/new-fields/PasswordField.vue'
import Button from '@/components/Button.vue'
import userApi from '@/modules/users/api'
import userStore from '@/modules/users/store'
import forgotPasswordDialogStore from '@/components/ForgotPasswordDialog/store'
import router from '../router'
import externalLoginProviders, { Provider } from '../../modules/external-login/providers'
import externalLoginPopup, { ExternalLoginCallbackResult } from '../../modules/external-login/popup'
import LoginDialogTemplateOptions from '../../options'
import externalLoginWelcomeDialogStore from '@/components/ExternalLoginWelcomeDialog/store'
import { trackGoal } from '@/utils/plausible'
import { lassoxAppsUrl } from '@/lasso-urls'
import { validateEmail, validatePassword } from '../../modules/validation'
import confirmEmailSentDialogStore from '@/components/ConfirmEmailSentDialog/store'

@Component({
  components: {
    LoadingSpinner,
    Header,
    Error,
    Button,
    TextField,
    PasswordField,
  },
})

export default class Home extends Vue {
  @Prop({ type: Object, required: true })
  options!: LoginDialogTemplateOptions

  loading = false

  userStore = userStore
  forgotPasswordDialogStore = forgotPasswordDialogStore
  router = router
  externalLoginProviders = externalLoginProviders

  error: string | null = null

  emailLoginModel = {
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  }

  get emailLoginHasErrors () {
    for (const field of Object.values(this.emailLoginModel)) {
      if (!!field.error) return true
    }

    return false
  }

  validateEmail () {
    this.emailLoginModel.email.error = validateEmail(this.emailLoginModel.email.value).error ?? ''
  }

  validatePassword () {
    this.emailLoginModel.password.error = validatePassword(this.emailLoginModel.password.value).error ?? ''
  }

  submitEmailLogin (event: SubmitEvent) {
    if (this.loading) return

    event.preventDefault()
    event.stopPropagation()

    this.loading = true
    this.error = null

    userApi.login({
      email: this.emailLoginModel.email.value,
      password: this.emailLoginModel.password.value,
    }).then(result => {
      if (!result.ok) {
        switch (result.data.errorCode) {
          case 10: {
            // that.loginError = 'Forkert brugernavn eller password'
            this.error = 'Forkert e-mail eller adgangskode.'
            break
          }

          case 14: {
            // let time = ('0' + new Date(error.body.errorMessage).getHours()).slice(-2) + ':' + ('0' + new Date(error.body.errorMessage).getMinutes()).slice(-2)
            // that.loginError = 'For mange loginforsøg. Prøv igen efter kl. ' + time + '.'
            const time = ('0' + new Date(result.data.errorMessage).getHours()).slice(-2) + ':' + ('0' + new Date(result.data.errorMessage).getMinutes()).slice(-2)
            this.error = 'For mange loginforsøg. Prøv igen efter kl. ' + time + '.'
            break
          }

          case 19: {
            // that.mustConfirmEmail = true
            // that.$store.state.loggingIn = true
            // setTimeout(() => {
            //   that.$router.push({ name: 'emailconfirmation' })
            // }, 300)
            userStore.hideLoginDialog()
            confirmEmailSentDialogStore.show(this.emailLoginModel.email.value)
            break
          }

          default: {
            this.error = 'Der skete en fejl. Prøv igen.'
            console.error('Email login failed. Data:', result.data)
            break
          }
        }

        this.loading = false
        return
      }

      const redirectUrl = new URL(`${lassoxAppsUrl}/login`)
      redirectUrl.searchParams.set('token', result.data)
      if (this.options.redirectUrl) redirectUrl.searchParams.set('returnUrl', this.options.redirectUrl)
      window.location.href = redirectUrl.toString()
    })
  }

  openExternalLoginPopup (provider: Provider) {
    this.error = null

    externalLoginPopup.open(provider).then(result => {

      if (result.type === 'NewUser') {
        trackGoal('Signup', {
          callback: () => this.redirectAfterExternalLogin(result, this.options.redirectUrl),
          props: {
            provider: provider,
            trigger: this.options.trackingEventName.replaceAll('+', ' ')
          },
        })
      } else {
        trackGoal('Login', {
          callback: () => this.redirectAfterExternalLogin(result, this.options.redirectUrl),
          props: {
            provider: provider,
            trigger: this.options.trackingEventName.replaceAll('+', ' ')
          }
        })
      }
    })
    .catch(error => {
      if (error.errorCode === 110) this.error = `Du har allerede en bruger, som logges ind via ${error.errorMessage}`
      else if (error.errorCode === 16) this.error = 'Du har allerede en bruger, som logges ind vha. e-mail.'
      else if (error.errorCode === 58) this.error = 'E-mails fra det valgte domæne er ikke tilladt.'
      else this.error = 'Noget gik galt'
      console.error('External login failed. Error:', error)
    })
  }

  redirectAfterExternalLogin = (result: ExternalLoginCallbackResult, returnUrl?: string) => {
    const redirectUrl = new URL(`${lassoxAppsUrl}/login`)
    redirectUrl.searchParams.set('token', result.signInToken)
    if (returnUrl) redirectUrl.searchParams.set('returnUrl', returnUrl)
    window.location.href = redirectUrl.toString()
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

.home {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .email-login {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 0;

    .email-login-header {
      .email-login-header-title {
        @include typography('title');
        margin: -4px 0;
      }

      .email-login-header-description {
        margin: -4px 0;
        margin-top: 12px - 4px;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 24px;
      column-gap: 16px;

      .button {
        margin-left: auto;
      }
    }
  }

  .separator {
    position: relative;
    margin: 24px 0;
    border-top: 1px solid #F1F1F1;

    &::after {
      content: 'eller';
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0 12px;
      font-size: 14px;
      line-height: 32px;
      background-color: white;
      transform: translate(-50%, -50%);
    }
  }

  .external-login {
    padding: 24px 0;
    text-align: center;

    .external-login-buttons {
      display: inline-flex;
      flex-direction: column;

      .external-login-button {
        flex-grow: 1;

        & + .external-login-button {
          margin-top: 16px;
        }

        .icon {
          width: 18px;
          height: 18px;
          margin-left: -4px;
          margin-right: 8px;

          &::v-deep svg {
            width: 100%;
            height: 100%;
            vertical-align: top;
          }
        }

        .label {
          flex-grow: 1;
          text-align: left;
        }
      }
    }
  }
}
</style>
