import Vue from 'vue'

const state = Vue.observable({
  show: false,
  email: '',
  lassoOrganizationId: '',
  emailRedirectUrl: '',
})

const confirmEmailSentDialogStore = {
  state,

  show (email: string, lassoOrganizationId?: string, emailRedirectUrl?: string) {
    state.show = true
    state.email = email
    state.lassoOrganizationId = lassoOrganizationId ?? ''
    state.emailRedirectUrl = emailRedirectUrl ?? ''
  },

  hide () {
    state.show = false
    state.email = ''
    state.lassoOrganizationId = ''
    state.emailRedirectUrl = ''
  },
}
export default confirmEmailSentDialogStore
