<template>
  <div id="appFooter" class="margin-bottom-2">
    <div class="content width-constraint container-fluid">
      <div class="row align-items-center">
        <div class="col-12 col-lg-2 col-xl-2 logo">
          <a href="https://lassox.com">
            <img src="../assets/img/lasso-x-logo.png" />
          </a>
        </div>
        <div class="col-12 col-lg-7 col-xl-7 links">
          <router-link :to="{ name: 'about' }">Om Lasso.dk</router-link>
          <router-link :to="{ name: 'contact' }">Kontakt os</router-link>
          <router-link :to="{ name: 'faq' }">Spørgsmål og svar</router-link>
          <a href="https://apps.lassox.com/assets/docs/privacy_cookies.pdf" target="_blank" @click="$root.trackEvent({eventCategory:'Click', eventAction: 'Privatlivspolitik', eventLabel: $route.fullPath})">Privatlivspolitik</a>
        </div>
        <div class="col-12 col-lg-3 col-xl-3 copyright">
          <span class="text-semimuted">Copyright {{year}} <a href="https://lassox.com">Lasso X</a> A/S</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'appFooter',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style lang="less" scoped>
  .content {
    padding-top: 50px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
    // display: flex;
    margin: 0 auto;
    .logo {
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      span {
        &:hover {
          text-decoration: none;
        }
      }
      img {
        height: 25px;
        margin: 0 5px;
      }
    }
    .links a {
      display: block;
      margin-top: 15px;
    }

    .logo, .links, .copyright {
      margin-top: 30px;
    }
  }

  #app.min-md {
    .links {
      text-align: center;
      a {
        display: inline;
        margin-left: 15px;
      }
    }
    .copyright {
      text-align: right;
    }
  }
</style>
