<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div class="modal-container">
          <div class="modal-top">
            <h3 style="margin: 0 0 20px 0">Tak for din bekræftelse!</h3>
            <p class="text-semimuted margin-top-0 margin-bottom-2">Du vil fremover modtage relevante produktopdateringer fra os. Du kan til enhver tid slette eller ændre dine kontaktinformationer fra de mails du modtager fra os fremover.</p>
          </div>
          <!-- <div class="modal-center">
            <form class="lasso-form">
              <p class="text-semimuted margin-top-bottom-1">Grundet ny EU-lovgivning (GDPR), skal vi bede om din accept til fortsat at måtte sende dig nyheder om firmaer og personer når der registreres ændringer på dem i CVR.</p>
              <p class="text-semimuted margin-bottom-1 margin-top-0">Du accepterer samtidig vores <a href="https://apps.lassox.com/assets/docs/privacy_cookies.pdf" target="_blank">privatlivspolitik</a>, som bl.a. beskriver hvordan vi opbevarer din e-mail.</p>
            </form>
          </div> -->
          <div class="modal-bottom text-right padding-top-bottom-1">
            <button class="btn btn-primary padding-left-right-2" @click="$emit('close')">LUK</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'confirmNewsletterModal'
}
</script>

<style lang="less" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 85%;
    max-width: 450px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    font-size: 15px;
    line-height: 28px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;

    .modal-top {
      padding: 30px 30px 0 30px;
      // background-color: #f8f8f8;
    }

    .modal-center {
      padding: 0 30px 30px 30px;
      // background-color: #f8f8f8;
    }

    .modal-bottom {
      border-top: 1px solid #ededed;
      border-radius: 0 0 2px 2px;
      padding: 15px;
      a.cancel {
        position: relative;
        top: 2px;
        margin-right: 20px;
        color: #777;
        cursor: pointer;
      }
      // box-shadow: 0 2px 5px -1px rgba(50, 50, 50, 0.2);
      position: relative;
      background-color: #f8f8f8;
    }

    .modal-footer {
      // background: linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent);
      background-color: #f8f8f8;
      text-align: center;
      font-size: 13px;
      a {
        text-decoration: underline
      }
    }
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

</style>
