<template>
	<div :id="bannerType" class="banner" :class="{ filled: isFilled }" v-if="show"></div>
</template>

<script>
import { EventBus } from '@/assets/js/helpers.js'
export default {
    props: {
        bannerType: String,
        refreshOnRouteChange: Boolean,
    },
		data () {
			return {
				isFilled: null
			}
		},
		computed: {
			show () {
				return !!window.concept
					&& ['home', 'companyStory', 'person', 'company'].indexOf(this.$route.name) > -1
					&& this.isFilled !== false
					&& this.$store.state.isAdTester
			},
		},
    methods: {
        refresh () {
            concept.injectAds(this.bannerType)
        },
				onFilled (elementId) {
					if (elementId === this.$el.id) {
						this.isFilled = true
						this.$emit('filled')
					}
				},
				onUnfilled (elementId) {
					if (elementId === this.$el.id) {
						this.isFilled = false
						this.$emit('unfilled')
					}
				}
    },
    watch: {
			'$route' (now, before)  {
					if (before.name && this.show) {
							this.refresh()
					}
			},
			show: {
				immediate: true,
				handler () {
					if (this.show) {
						concept.injectNewAds(this.bannerType)
						EventBus.$on('filledAd', this.onFilled)
						EventBus.$on('unfilledAd', this.onUnfilled)
					}
				}
			}
    },
    mounted () {
    },
		beforeDestroy () {
			EventBus.$off('filledAd', this.onFilled)
			EventBus.$off('unfilledAd', this.onUnfilled)
		}

};

</script>

<style lang="scss" scoped>
.banner {

}
</style>