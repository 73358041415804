<template>
  <TextField
    ref="textField"
    :autofocus="autofocus"
    :label="label"
    :required="required"
    :name="name"
    :type="showPassword ? 'text' : 'password'"
    :autocomplete="autocomplete"
    :value="value"
    :trailingIcon="showPassword ? 'visibility' : 'visibility_off'"
    :onClickTrailingIcon="() => showPassword = !showPassword"
    :helperText="helperText"
    :errorText="errorText"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script lang="ts">
import { Vue, Component, Prop, Model, Ref } from 'vue-property-decorator'
import TextField from './TextField.vue'

@Component({
  components: {
    TextField,
  },
})

export default class PasswordField extends Vue {
  @Prop(Boolean)
  readonly autofocus!: boolean

  @Prop(String)
  readonly label?: string

  @Prop(Boolean)
  readonly required!: boolean

  @Prop(String)
  readonly name?: string

  @Prop(String)
  readonly autocomplete?: string

  @Model('input', { type: String, default: '' })
  readonly value!: string

  @Prop(String)
  readonly helperText?: string

  @Prop(String)
  readonly errorText?: string

  @Ref()
  readonly textField!: TextField

  showPassword = false
}
</script>
